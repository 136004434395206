/* eslint-disable @typescript-eslint/quotes */
const messages = {
  profile: 'Thông tin cá nhân',
  changePassword: 'Đổi mật khẩu',
  logout: 'Đăng xuất',
  firstName: 'Họ',
  lastName: 'Tên',
  username: 'Tên đăng nhập',
  email: 'Email',
  phone: 'Số điện thoại',
  email2: 'Email khác',
  phone2: 'Số điện thoại khác',
  lastLogin: 'Đăng nhập lần cuối',
  lastLogout: 'Đăng xuất lần cuối',
  isActive: 'Kích hoạt TK',
  isVerifyEmail: 'Xác thực Email',
  status: 'Trạng thái',
  group: 'Nhóm tài khoản',
  groups: 'Nhóm tài khoản',
  gender: 'Giới tính',
  birthday: 'Sinh nhật',
  address: 'Địa chỉ',
  nationality: 'Quốc tịch',
  ethicity: 'Dân tộc',
  religion: 'Tôn giáo',
  note: 'Ghi chú',
  password: 'Mật khẩu',
  oldPassword: 'Mật khẩu cũ',
  newPassword: 'Mật khẩu mới',
  confirmPassword: 'Nhập lại mật khẩu',
  using: 'Đang sử dụng',
  temp_locking: 'Tạm khoá',
  locking: 'Đang khoá',
  blacklist: 'Danh sách đen',
  cancel: 'Đã huỷ',
  default_group: 'Nhóm mặt định',
  private_group: 'Nhóm bí mật',
  custom_group: 'Nhóm tuỳ chọn',
  male: 'Nam',
  female: 'Nữ',
  secret: 'Không muốn tiết lộ',
  none: 'Không',
  lot: 'Nhiều',
  serial: 'Định kỳ',
  resources: 'Tài nguyên',
  amount: 'Số lượng',
  comment: 'Ghi chú',
  dark: 'Tối',
  light: 'Sáng',
  keyword: 'Từ khoá',
  domain: 'Tên miền',
  login: 'ĐĂNG NHẬP',
  detail: 'Thông tin chi tiết',
  dashboard: 'Trang chủ',
  second: 'giây',
  day: 'ngày',
  confirm: 'Xác nhận',
  copied: 'Đã copy',
  import_button: 'Nhập dữ liệu',
  dateOfData: 'Ngày nhập dữ liệu',
  isOverride: 'Ghi đè',
  continue: 'Tiếp tục',
  resend_email: 'Gửi lại',
  reset_password: 'Đặt lại mật khẩu',
  reset_your_password: 'Đặt lại mật khẩu',
  check_your_email: 'Kiểm tra email của bạn',
  type_password_to_reset: 'Nhập một mật khẩu mới để đặt lại mật khẩu của bạn.',
  password_change: 'Mật khẩu đã thay đổi!',
  password_change_msg: 'Mật khẩu của bạn đã thay đổi thành công.',
  back_login_page: 'Về trang đăng nhập',
  forgot_password: 'Bạn quên mật khẩu?',
  wifiPassword: 'Mật khẩu wifi',
  menu: {
    'Home Page': 'Trang chủ',
    'Intergrations Management': 'Quản lý tích hợp',
    Modules: 'Mô đun',
    'Api Keys': 'API keys',
    'Screen Groups': 'Nhóm màn hình',
    Screens: 'Màn hình',
    Functions: 'Chức năng',
    Menus: 'Menus',
    'API Groups': 'Nhóm API',
    API: 'API',
    Webhook: 'Webhook',
    'Users Management': 'Quản lý người dùng',
    'Account Groups': 'Nhóm tài khoản',
    Accounts: 'Tài khoản',
    Teachers: 'Giảng viên',
    Students: 'Sinh viên',
    'Subjects Management': 'Quản lý học phần',
    Subjects: 'Học phần',
    'Subject Classes': 'Lớp học phần',
    'Master Management': 'Quản lý chung',
    Units: 'Đơn vị',
    Departments: 'Phòng ban',
    Courses: 'Khoá',
    'Activity Classes': 'Lớp sinh hoạt',
    Scholastics: 'Năm học',
    Semesters: 'Học kỳ',
    'System Management': 'Quản lý hệ thống',
    Settings: 'Cài đặt',
    'Log Exceptions': 'Exceptions Log',
    'Log Mails': 'Email Log',
  },
  api: {
    'Get activity classes': 'Lấy danh sách lớp sinh hoạt',
    'Get detail activity class': 'Lấy chi tiết lớp sinh hoạt',
    'Get clubs': 'Lấy danh sách câu lạc bộ',
    'Get detail club': 'Lấy chi tiết câu lạc bộ',
    'Get setting': 'Lấy cài đặt',
    'Get app state': 'Lấy trạng thái ứng dụng',
    'Get communes': 'Lấy danh sách xã',
    'Get courses': 'Lấy danh sách khoá học',
    'Get detail course': 'Lấy chi tiết khoá học',
    'Get districts': 'Lấy danh sách quận',
    'Add mail content to send by batch': 'Thêm nội dung thư để gửi batch',
    'Test send email': 'Kiểm tra gửi email',
    'Get error messages': 'Lấy danh sách thông báo lỗi',
    'Get detail error messages': 'Lấy chi tiết thông báo lỗi',
    'Get faculties': 'Lấy danh sách khoa',
    'Get detail faculty': 'Lấy thông tin chi tiết giảng viên',
    'Forgot password': 'Quên mật khẩu',
    'Check token forgot password': 'Kiểm tra mã quên mật khẩu',
    'Reset password': 'Đặt lại mật khẩu',
    Login: 'Đăng nhập',
    'Refresh token': 'Làm mới token',
    Logout: 'Đăng xuất',
    'Get majors': 'Lấy danh sách chuyên ngành',
    'Get detail major': 'Lấy chi tiết chuyên ngành',
    'Get token': 'Lấy token',
    'Get type of student': 'Lấy loại sinh viên',
    'Get foreign languages': 'Lấy danh sách ngoại ngữ',
    'Get profile': 'Lấy thông tin tài khoản',
    'Get base profile': 'Lấy thông tin tài khoản cơ bản',
    'Save profile': 'Lưu thông tin tài khoản',
    'Change password': 'Đổi mật khẩu',
    'Get provinces': 'Lấy danh sách tỉnh',
    'Get roles': 'Lấy danh sách phân quyền',
    'Get detail role': 'Lấy chi tiết phân quyền',
    'Get scholatics': 'Lấy danh sách năm học',
    'Get detail scholatic': 'Lấy chi tiết năm học',
    'Get semesters': 'Lấy danh sách học kì',
    'Get detail semester': 'Lấy chi tiết học kì',
    'Get students': 'Lấy danh sách học sinh',
    'Get students (base information)': 'Lấy danh sách học sinh (thông tin cơ bản)',
    'Get detail student': 'Lấy chi tiết thông tin sinh viên',
    'Get detail student (base information)': 'Nhận chi tiết sinh viên (Thông tin cơ sở)',
    'Get teachers of student': 'Lấy danh sách giáo viên của sinh viên',
    'Get teachers': 'Lấy danh sách sinh viên',
    'Get teachers (base information)': 'Lấy danh sách sinh viên (thông tin cơ bản)',
    'Get detail teacher': 'Nhận chi tiết giáo viên',
    'Get detail teacher (base information)': 'Lấy chi tiết giáo viên (thông tin cơ sở)',
    'Check major of student and teacher': 'Kiểm tra chuyên ngành của học sinh và giáo viên',
    'Get init data for user': 'Lấy dữ liệu cơ sở cho người dùng',
    'Get users': 'Lấy danh sách người dùng',
    'Delete user': 'Xoá người dùng',
    'Delete users': 'Xoá nhiều người dùng',
    'Get YUS classes': 'Lấy danh sách các liên chi đoàn/chi đoàn',
    'Get detail YUS class': 'Lấy thông tin chi tiết của liên chi đoàn/chi đoàn',
    'Get permission': 'Lấy thông tin phân quyền',
    'Get init data for teacher': 'Lấy dữ liệu cơ sở cho giáo viên',
  },
  functions: {
    'Search roles': 'Tìm kiếm nhóm quyền',
    'Show role detail': 'Xem chi tiết nhóm quyền',
    'Add role': 'Thêm nhóm quyền',
    'Edit role': 'Chỉnh sửa nhóm quyền',
    'Delete role': 'Xoá nhóm quyền',
    'Grant permission': 'Chỉnh sửa phân quyền',
    Settings: 'Cài đặt',
    General: 'Tổng quan',
    Email: 'E-mail',
    'Foreign language': 'Ngoại ngữ',
    'Type of student': 'Loại sinh viên',
    'Type of teacher': 'Loại giảng viên',
    'Position in class': 'Vị trí trong lớp',
    'Position in faculty': 'Vị trí trong khoa',
    'Position in major': 'Vị trí trong chuyên ngành',
    'Position in YUS': 'Vị trí trong đoàn',
    'Position in CPV': 'Vị trí trong đảng',
    'Position in department': 'Vị trí trong phòng ban',
    Logs: 'Nhật ký',
    'Call API': 'Gọi API',
    Notification: 'Thông báo',
    Exception: 'Ngoại lệ',
    'Export module data': 'Xuất dữ liệu mô -đun',
    'Import module data': 'Nhập dữ liệu mô -đun',
    'Blob storage setting': 'Cài đặt lưu trữ blob',

    'Search modules': 'Tìm kiếm mô-đun',
    'Show module detail': 'Hiển thị chi tiết mô-đun',
    'Add module': 'Thêm mô-đun',
    'Edit module': 'Chỉnh sửa mô-đun',
    'Delete module': 'Xóa mô-đun',
    'Search API keys': 'Tìm kiếm API Keys',
    'Show API key': 'Xem chi tiết API Keys',
    'Add API key': 'Thêm API Keys',
    'Edit API key': 'Chỉnh sửa API Keys',
    'Delete API key': 'Xóa API Keys',
    'Search screen groups': 'Tìm kiếm nhóm màn hình',
    'Show screen group detail': 'Xem chi tiết nhóm màn hình',
    'Add screen group': 'Thêm nhóm màn hình',
    'Edit screen group': 'Chỉnh sửa nhóm màn hình',
    'Delete screen group': 'Xóa nhóm màn hình',
    'Search screens': 'Tìm kiếm màn hình',
    'Show screen detail': 'Xem chi tiết màn hình',
    'Add screen': 'Thêm màn hình',
    'Edit screen': 'Chỉnh sửa màn hình',
    'Delete screen': 'Xóa màn hình',
    'Search functions': 'Tìm kiếm chức năng',
    'Show function detail': 'Xem chi tiết chức năng',
    'Add function': 'Thêm chức năng',
    'Edit function': 'Chỉnh sửa chức năng',
    'Delete function': 'Xóa chức năng',
    'Search menu items': 'Tìm kiếm menu',
    'Show menu item detail': 'Xem chi tiết menu',
    'Add menu item': 'Thêm menu',
    'Edit menu item': 'Chỉnh sửa menu',
    'Delete menu item': 'Xóa menu',
    'Preview menu': 'Xem trước menu',
    'Arange menu': 'Sắp xếp Menu',
    'Search API groups': 'Tìm kiếm nhóm API',
    'Show API group detail': 'Xem chi tiết nhóm API',
    'Add API group': 'Thêm nhóm API',
    'Edit API group': 'Chỉnh sửa nhóm API',
    'Delete API group': 'Xóa nhóm API',
    'Search API': 'Tìm kiếm API',
    'Show API detail': 'Hiển thị chi tiết API',
    'Add API': 'Thêm API',
    'Edit API': 'Chỉnh sửa API',
    'Delete API': 'Xóa API',
    'Search webhook': 'Tìm kiếm webhook',
    'Show webhook detail': 'Hiển thị chi tiết webhook',
    'Add webhook': 'Thêm webhook',
    'Edit webhook': 'Chỉnh sửa webhook',
    'Delete webhook': 'Xóa webhook',

    'Search users': 'Tìm kiếm người dùng',
    'Show user detail': 'Xem chi tiết người dùng',
    'Add user': 'Thêm người dùng',
    'Edit user': 'Chỉnh sửa người dùng',
    'Delete user': 'Xóa người dùng',
    'Import users': 'Import người dùng',
    'Export users': 'Export người dùng',
    'Lock account': 'Khoá tài khoản',
    'Unlock account': 'Mở khoá tài khoản',
    'Add account to black list': 'Thêm tài khoản vào danh sách đen',
    'Remove account from black list': 'Xóa tài khoản khỏi danh sách đen',
    'Search students': 'Tìm kiếm sinh viên',
    'Show student detail': 'Xem chi tiết sinh viên',
    'Add student': 'Thêm sinh viên',
    'Edit student': 'Chỉnh sửa sinh viên',
    'Delete student': 'Xóa sinh viên',
    'Import student': 'Import sinh viên',
    'Export student': 'Export sinh viên',
    'Search teachers': 'Tìm kiếm giảng viên',
    'Show teacher detail': 'Xem chi tiết giảng viên',
    'Add teacher': 'Thêm giảng viên',
    'Edit teacher': 'Chỉnh sửa giảng viên',
    'Delete teacher': 'Xóa giảng viên',
    'Import teacher': 'Import giảng viên',
    'Export teacher': 'Export giảng viên',
    'Edit role student': 'Chỉnh sửa phân quyền sinh viên',
    'Edit role teacher': 'Chỉnh sửa phân quyền giảng viên',

    'Search subjects': 'Tìm kiếm học phần',
    'Show subject detail': 'Xem chi tiết học phần',
    'Add subject': 'Thêm học phần',
    'Edit subject': 'Chỉnh sửa học phần',
    'Delete subject': 'Xoá học phần',
    'Search subject classes': 'Tìm kiếm lớp học phần',
    'Show subject class detail': 'Xem chi tiết lớp học phần',
    'Add subject class': 'Thêm lớp học phần',
    'Edit subject class': 'Chỉnh sửa lớp học phần',
    'Delete subject class': 'Xóa lớp học phần',

    'View calendar': 'Xem lịch',
    'Search faculties': 'Tìm kiếm khoa',
    'Show faculty detail': 'Xem chi tiết khoa',
    'Add faculty': 'Thêm khoa',
    'Edit faculty': 'Chỉnh sửa khoa',
    'Delete faculty': 'Xoá khoa',
    'Search departments': 'Tìm kiếm phòng ban',
    'Show department detail': 'Xem chi tiết phòng ban',
    'Add department': 'Thêm phòng ban',
    'Edit department': 'Chỉnh sửa phòng ban',
    'Delete department': 'Xoá phòng ban',
    'Search courses': 'Tìm kiếm khoá',
    'Show course detail': 'Xem chi tiết khoá',
    'Add course': 'Thêm khoá',
    'Edit course': 'Chỉnh sửa khoá',
    'Delete course': 'Xoá khoá',
    'Search activity classes': 'Tìm kiếm lớp sinh hoạt',
    'Show activity class detail': 'Xem chi tiết lớp sinh hoạt',
    'Add activity class': 'Thêm lớp sinh hoạt',
    'Edit activity class': 'Chỉnh sửa lớp sinh hoạt',
    'Delete activity class': 'Xoá lớp sinh hoạt',
    'Search scholastics': 'Tìm kiếm năm học',
    'Show scholastic detail': 'Xem chi tiết năm học',
    'Add scholastic': 'Thêm năm học',
    'Edit scholastic': 'Chỉnh sửa năm học',
    'Delete scholastic': 'Xoá năm học',
    'Search semesters': 'Tìm kiếm học kì',
    'Show semester detail': 'Xem chi tiết học kỳ',
    'Add semester': 'Thêm học kỳ',
    'Edit semester': 'Chỉnh sửa học kỳ',
    'Delete semester': 'Xóa học kỳ',
  },
  screens: {
    'Roles management': 'Quản lý vai trò',
    Settings: 'Cài đặt',
    Logs: 'Nhật ký',
    'Modules management': 'Quản lý mô -đun',
    'API key management': 'Quản lý khóa API',
    'Screen groups management': 'Quản lý nhóm màn hình',
    'Screens management': 'Quản lý màn hình',
    'Functions management': 'Quản lý chức năng',
    'Menu management': 'Quản lý menu',
    'Api groups management': 'Quản lý nhóm API',
    'Api management': 'Quản lý API',
    'Webhook management': 'Quản lý webhook',

    'User management': 'Quản lý người dùng',
    'Teacher management': 'Quản lý giảng viên',
    'Student management': 'Quản lý sinh viên',
    'Subjects management': 'Quản lý học phần',
    'Subject classes management': 'Quản lý lớp học phần',
    Dashboard: 'Trang chủ',
    'Faculties management': 'Quản lý khoa',
    'Departments management': 'Quản lý phòng ban',
    'Courses management': 'Quản lý khóa',
    'Activity classes management': 'Quản lý các lớp sinh hoạt',
    'Scholastics management': 'Quản lý năm học',
    'Semesters management': 'Quản lý học kỳ',
  },
};

export default messages;
